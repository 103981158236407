<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		<div class="radio">
			<el-radio v-model="selectRadio" label="58pic" border>千图网</el-radio>
			<el-radio v-model="selectRadio" label="588ku" border>千库网</el-radio>
			<el-radio v-model="selectRadio" label="33591" border>33登陆</el-radio>
		</div>
		<div class="input" v-if="selectRadio!=33591">
			<input type="text" @input="mazmun($event)" placeholder="请输入ID"/>
			<div class="btn" @click="xiazai()">下 载</div>
		</div>
		<div class="input" v-if="selectRadio==33591">
			<input type="text" @input="mazmun($event)" placeholder="请输入用户名"/>
			<input type="text" class="pass" @input="pass($event)" placeholder="请输入密码"/>
			<div class="btn" @click="login()">登 陆</div>
		</div>
		
		<div class="userInfo" v-if="userInfo.username && selectRadio==33591">
			
<!-- <authimg  :authSrc="'https:'+userInfo.avatar" /> -->
<img referrerpolicy='no-referrer' :src="userInfo.avatar"/>
		用户名：{{userInfo.username}}
		<br>
		金币：{{userInfo.coin}}<br>
		积分：{{userInfo.integral}}<br/>
		等级：<img v-for="(item,index) in level" :src="item"/> ({{userInfo.level}})<br>
		下一等级：{{userInfo.next_level}}<br>
		签到：{{userInfo.qiandao}}/天 <div class="sign" @click="sign()" v-if="!userInfo.is_sign">签到</div><br>
		人气：{{userInfo.hits}}<br>
		下一VIP等级：{{userInfo.vip_next_level}}<br>
		VIP到期时间：{{userInfo.vip_time}}
		
		<div class="zhichi">
			<div class="item" v-for="(item,index) in userInfo.zhichi" :key='index'>
				<img :src="item.image" referrerpolicy='no-referrer'/>
				<div class="name">{{item.name}}</div>
			</div>
		</div>
		<div class="zhichi">
			<div class="item" v-for="(item,index) in userInfo.views" :key='index'>
				<img :src="item.image" referrerpolicy='no-referrer'/>
				<div class="name">{{item.name}}</div>
			</div>
		</div>
		</div>
	</div>
</template>


<script>
	import  authimg  from "@/components/authImg.vue"
	import {down,b33591} from '@/api/admin/login'
	import dj1 from '@/assets/dj1.gif'
	import dj2 from '@/assets/dj2.gif'
	import dj3 from '@/assets/dj3.gif'
	import dj4 from '@/assets/dj4.gif'
	export default {
		name:'down',
		components: {
			authimg
		},
		data(){
			return {
				keyword:'weli',
				password:'',
				down_url:'',
				selectRadio:"58pic",
				code:'',
				ver:'',
				userInfo:{},
				fullscreenLoading: false,
				level:''
			}
		},
		methods: {
			mazmun(e){
				this.keyword = e.currentTarget.value
			},
			pass(e){
				this.password = e.currentTarget.value
			},
			
			login(){
				if(!this.keyword){
					this.$message({
					  message: '请输入用户名',
					  type: 'error'
					});
					return false
				}else if(!this.password){
					this.$message({
					  message: '请输入密码',
					  type: 'error'
					});
					return false
				}
				this.fullscreenLoading = true;
				if(this.code){
					this.fullscreenLoading = true;
					b33591('https://tamgha.gulum.vip/index/weli/login/name/'+this.keyword+'/pass/'+this.password,{v:this.ver,co:this.code}).then(res=>{
						// console.log('weli111',res.data)
						if(!this.ver){
							this.ver = res.data
						}
						this.ucenter()
					   }).catch(err=>{
					       console.log('fucker',err);
					   });
					   return false;
				}
				b33591('https://tamgha.gulum.vip/index/weli/code').then(res=>{
					this.code = res.data
					this.$message({
					  message: '获取验证码成功',
					  type: 'success'
					});
					this.fullscreenLoading=false
				   }).catch(err=>{
				       console.log('fucker',err);
				   });
			},
			
			sign(){
				this.fullscreenLoading = true;
				b33591('https://tamgha.gulum.vip/index/weli/sign').then(res=>{
					var data = res.data
					if (data.indexOf("qiandao_ok") > -1) {
						this.$message({
						  message: '签到成功',
						  type: 'success'
						});
						this.ucenter()
					} else if (data == "qiandao_lose") {
						this.$message({
						  message: '连续签到1次',
						  type: 'success'
						});
						this.ucenter()
					} else if (data == "qiandao_again") {
						this.$message({
						  message: '今天不能重复签到',
						  type: 'warning'
						});
					} else if (data == "qiandao_end") {
						this.$message({
						  message: '恭喜您！，您已连续签到30次，经获得签到任务奖励100金币！',
						  type: 'success'
						});
						this.ucenter()
					} else if (data == "error") {
						this.$message.error('获取用户信息错误！请重新登录帐户再试！');
					} else {
						this.$message.error('系统忙~！稍后再签到吧');
					}
					
					this.fullscreenLoading=false
				   }).catch(err=>{
				       console.log('fucker',err);
				   });
			},
			
			ucenter(){
				b33591('https://tamgha.gulum.vip/index/weli/ucenter').then(res=>{
					// console.log('weli111',res.data)
					this.userInfo = res.data
					var __level = JSON.parse(res.data.integral)
					var dengji = 1;
					for (var i = 1; i < 100; i++)
					{
						if (__level < (i * (i + 2)))
						{
							dengji = i;
							break;
						}
					}
					// console.log('dengji',dengji)
					this.userInfo.level = dengji
					var retStr = [];
					var num0, num1, num2, num3;
					num0 = (dengji-dengji%64) / 64;
					num1 = ((dengji % 64)-(dengji % 64)%16) / 16;
					num2 = ((dengji % 16)-(dengji % 16)%4) / 4;
					num3 = (dengji % 16) % 4;
					for (var i = 0; i < num0; i++)
					{
						// retStr += "https://weli.33591.com/images/dj4.gif";
						retStr.push(dj4)
					}
					for (var i = 0; i < num1; i++)
					{
						// retStr += "https://weli.33591.com/images/dj3.gif";
						retStr.push(dj3)
					}
					for (var i = 0; i < num2; i++)
					{
						// retStr += "https://weli.33591.com/images/dj2.gif";
						retStr.push(dj2)
					}
					for (var i = 0; i < num3; i++)
					{
						//retStr += "https://weli.33591.com/images/dj1.gif";
						retStr.push(dj1)
					}
					// console.log('weliii',retStr)
					this.level = retStr
					this.$message({
					  message: '登陆成功',
					  type: 'success'
					});
					this.fullscreenLoading=false
				   }).catch(err=>{
				       console.log('fucker',err);
				   });
			},
			
			xiazai(){
				console.log(this.keyword)
				if(!this.keyword){
					this.$notify.error({
					  title: '错误',
					  message: '请输入素材ID',
					  duration:3000
					});
					return false;
				}
				down({id:this.keyword,type:this.selectRadio}).then(res=>{
					console.log(res)
					if(this.selectRadio=='58pic'){
						this.down_url = res.data.tc_url
						window.location.href = res.data.tc_url
					}else if(this.selectRadio=='588ku'){
						console.log('weli',res)
						this.down_url = res.data.tc_url
						window.location.href = res.data.tc_url
					}
				   }).catch(err=>{
				       console.log('fucker',err);
				   });
			}
		},
		mounted(){
			
		}
		

	}
</script>
<style >
	.zhichi {
		overflow: hidden;
		display: flex;
		justify-content: start;
		align-items: center;
	}
	.zhichi .item {
		width: 100px;
	}
	.zhichi .item img {
		display: block;
		margin: 0 auto;
	}
	.zhichi .item .name {
		overflow: hidden;
		text-align: center;
		font-size: 12px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.userInfo{
		overflow: hidden;
		padding: 20px;
		line-height: 30px;
	}
	.radio {
		display: flex;
		justify-content: start;
		align-items: center;
		padding: 20px;
		direction: ltr;
	}
	.input {
		direction: ltr;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px 20px;
	}
	.input input {
		overflow: hidden;
		outline: none;
		border:1px solid #eee;
		height: 40px;
		line-height: 40px;
		padding: 0 10px;
		width: 100%;
	}
	.input .pass {
		margin-left: 10px;
	}
	.input .btn {
		overflow: hidden;
		background-color: #F56C6C;
		color:#fff;
		margin-left: 10px;
		width: 200px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 12px;
		font-weight: bold;
	}
</style>